/*
Author: Hoi Tak Leung
Email: hoi.leung@greedy.mx
Last Update: 26/09/2023
*/

import React from "react";

import { AppBar, Box, Toolbar, Container, CssBaseline, Grid } from '@mui/material';

import Terms from './documents/Terms';
import Privacy from './documents/Privacy';
import Cookies from './documents/Cookies';

import Footer from "../footer/Footer";

import './Legal.css';
import blueLogo from '../../assets/greedy_logo_blue_350x75.png';

export default function Legal({legalDocument}){
    const handleLogoClick = () =>{
        window.location.href = '/';
    }
    return(
        <Container disableGutters maxWidth={false} className='legal-container'>
            <CssBaseline />
            <AppBar className='navbar-solid'>
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                    <Box className='navbar-logo' onClick={()=>{handleLogoClick()}} sx={{ display: { xs: "none", md: "flex" }}}>
                        <img src={blueLogo} alt="Greedy Logo" href='/' />
                    </Box>
                    <Box className="mobile-navbar-logo" onClick={()=>{handleLogoClick()}} sx={{ display: { xs: "flex", md: "none" }}}>
                        <img src={blueLogo}  alt="Greedy Logo"/>
                    </Box>
                    </Toolbar>
                </Container>
            </AppBar>
            <Grid container>
                <Grid item xs={1} xl={2}></Grid>
                <Grid item xs={10} xl={8} className='legal-grid'>
                    <Box className='legal-box'>
                        {legalDocument === 'TERMS' && <Terms />}
                        {legalDocument === 'PRIVACY' && <Privacy />}
                        {legalDocument === 'COOKIES' && <Cookies />}                        
                    </Box>
                    
                </Grid>
                <Grid item xs={1} xl={2}></Grid>
            </Grid>
            <Footer />
        </Container>
            
    );
}