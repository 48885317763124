/*
Author: Hoi Tak Leung
Email: hoi.leung@greedy.mx
Last Update: 25/09/2023
*/

import React from "react";
import { Typography, Container, CssBaseline, Grid, Box } from '@mui/material';

import ImgDefinition from '../../../assets/greedy_definition.png';

import './AboutSection.css';

export default function AboutSection(){
    return(
        <Container disableGutters maxWidth={false} className='container-section-about'>
            <CssBaseline />
            <Grid container>
                <Grid item xs={12} lg={6} xl={6}>
                    <img src={ImgDefinition} className='img-greedy-definition' alt='Definición Greedy' />
                </Grid>
                <Grid item container xs={12} lg={6} xl={6} className="section-about-grid">
                    <Grid item xs={1}></Grid>
                    <Grid item xs={10} sx={{margin:'auto'}}>
                        <Box className='section-about-box'>
                            <Typography variant="h3" className='section-about-header'>¿Por qué GREEDY?</Typography>
                            <Typography variant="h5" className='section-about-description'>
                                Somos optimistas por naturaleza, por lo que creemos firmemente en el lado bueno de las cosas. 
                                Sabemos que nuestros clientes tienen el <b>afán desmedido y deseo vehemente</b> de convertir
                                en realidad sus ideas y proyectos tecnológicos por lo que merecen un socio tecnológico que
                                tenga el mismo afán y deseo por cumplir sus expectativas.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={1}></Grid>
                    
                </Grid>
            </Grid>
        </Container>
    );
}