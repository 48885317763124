/*
Author: Hoi Tak Leung
Email: hoi.leung@greedy.mx
Last Update: 25/09/2023
*/

import React from "react";
import { Typography, Container, CssBaseline, Grid, Box } from '@mui/material';

import IconEnergy from '../../../assets/industries-icons/energy-icon-solid.png';
import IconBank from '../../../assets/industries-icons/bank-icon-solid.png';
import IconConstruction from '../../../assets/industries-icons/construction-icon-solid.png';
import IconConsulting from '../../../assets/industries-icons/consulting-icon-solid.png';
import IconFinance from '../../../assets/industries-icons/finance-icon-solid.png';
import IconHS from '../../../assets/industries-icons/hardware-software-icon-solid.png';
import IconInsurance from '../../../assets/industries-icons/insurance-icon-solid.png';
import IconMassiveGoods from '../../../assets/industries-icons/massive-goods-icon-solid.png';
import IconRealState from '../../../assets/industries-icons/real-state-icon-solid.png';
import IconRetail from '../../../assets/industries-icons/retail-icon-solid.png';
import IconTelecomm from '../../../assets/industries-icons/telecomm-icon-solid.png';
import IconStockMarket from '../../../assets/industries-icons/stock-market-icon-solid.png';

import SectionBack from '../../../assets/stock-coworking.jpg';

import './IndustriesSection.css';

export default function IndustriesSectionSection(){
    return(
        <Container disableGutters maxWidth={false} className='container-section-industries'>
            <CssBaseline />
            <Grid container>
                <Grid item container xs={12} sx={{display:{xs:'block', lg:'none', xl:'none'}}}>
                    <img src={SectionBack} className="mobile-section-background" alt='Imagen Múltiples Industrias' />
                </Grid>
                <Grid item container xs={12} lg={6} xl={6} sx={{marginTop:'4vh', marginBottom:'5vh'}}>
                    <Grid item container xs={1} lg={2}></Grid>
                    <Grid item container xs={10} lg={9}>
                        <Box className="title-box">
                            <Typography variant="h3" sx={{textAlign:{ xs:'center', lg:'left',xl:'left'}}} className='section-industries-header'>Múltiples Industrias</Typography>
                        </Box>
                        <Typography variant="h5" className='section-industries-description'>
                            Hemos tenido la oportunidad de participar activamente en diversos proyectos que abarcan
                            un amplio número de industrias y sectores que se encuentran en diferentes facetas tecnológicas.
                        </Typography>
                        <Grid container spacing={2} sx={{textAlign:'center', color:'black'}}>
                            <Grid item xs={4} lg={3}>
                                <Box className='industries-list-box'>
                                    <img src={IconEnergy} alt="Ícono Energía"/>
                                    <Typography variant="subtitle1">Energía</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={4} lg={3}>
                                <Box className='industries-list-box'>
                                    <img src={IconTelecomm} alt="Ícono Telecomunicaciones"/>
                                    <Typography variant="subtitle1">Telecomunicaciones</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={4} lg={3}>
                                <Box className='industries-list-box'>
                                    <img src={IconRetail} alt="Ícono Retail"/>
                                    <Typography variant="subtitle1">Retail</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={4} lg={3}>
                                <Box className='industries-list-box'>
                                    <img src={IconMassiveGoods} alt="Ícono Consumo Masivo"/>
                                    <Typography variant="subtitle1">Consumo Masivo</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={4} lg={3}>
                                <Box className='industries-list-box'>
                                    <img src={IconConstruction} alt="Ícono Construcción"/>
                                    <Typography variant="subtitle1">Construcción</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={4} lg={3}>
                                <Box className='industries-list-box'>
                                    <img src={IconBank} alt="Ícono Banca"/>
                                    <Typography variant="subtitle1">Banca</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={4} lg={3}>
                                <Box className='industries-list-box'>
                                    <img src={IconFinance} alt="Ícono Finanzas y Contabilidad"/>
                                    <Typography variant="subtitle1">Finanzas y Contabilidad</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={4} lg={3}>
                                <Box className='industries-list-box'>
                                    <img src={IconStockMarket} alt="Ícono Mercado de Capitales"/>
                                    <Typography variant="subtitle1">Mercado de Capitales</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={4} lg={3}>
                                <Box className='industries-list-box'>
                                    <img src={IconInsurance} alt="Ícono Seguros"/>
                                    <Typography variant="subtitle1">Seguros</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={4} lg={3}>
                                <Box>
                                    <img src={IconRealState} alt="Ícono Inmobiliario"/>
                                    <Typography variant="subtitle1">Inmobiliario</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={4} lg={3}>
                                <Box className='industries-list-box'>
                                    <img src={IconConsulting} alt="Ícono Consultoría y Asesoría"/>
                                    <Typography variant="subtitle1">Consultoría y Asesoría</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={4} lg={3}>
                                <Box className='industries-list-box'>
                                    <img src={IconHS} alt="Ícono Hardware y Software"/>
                                    <Typography variant="subtitle1">Hardware y Software</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container xs={1} lg={1}></Grid>
                </Grid>
                <Grid item container lg={6} xl={6} sx={{display:{xs:'none', lg:'block', xl:'block'}, height:'inherit'}}>
                    <img src={SectionBack} className='section-industries-background' alt='Imagen Múltiples Industrias' />
                </Grid>
            </Grid>
        </Container>
    );
}