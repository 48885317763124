/*
Author: Hoi Tak Leung
Email: hoi.leung@greedy.mx
Last Update: 25/09/2023
*/

import * as React from 'react';
import './App.css';

import Landing from './components/landing/Landing';
import Legal from './components/legal/Legal';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme();
theme = responsiveFontSizes(theme);

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/legal" element={<Legal legalDocument={null} />} />
          <Route path="/legal/terms" element={<Legal legalDocument={'TERMS'} />} />
          <Route path="/legal/privacy" element={<Legal legalDocument={'PRIVACY'} />} />
          <Route path="/legal/cookies" element={<Legal legalDocument={'COOKIES'} />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>

  );
}