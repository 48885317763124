/*
Author: Hoi Tak Leung
Email: hoi.leung@greedy.mx
Last Update: 25/09/2023
*/

import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Navigation } from 'swiper/modules';
import { Typography, Card, Grid, Box } from '@mui/material';

import CardIconWeb from '../../../../assets/service-card-icons/v1_web_design.png';
import CardIconApp from '../../../../assets/service-card-icons/v1_app_dev.png';
import CardIconIoT from '../../../../assets/service-card-icons/v1_internet_of_things.png';
import CardIconAuto from '../../../../assets/service-card-icons/v1_automation.png';
import CardIconCloud from '../../../../assets/service-card-icons/v1_cloud.png';
import CardIconConsult from '../../../../assets/service-card-icons/v1_consultory.png';

import TL_React from '../../../../assets/technologies-logo/reactjs.png';
import TL_Angular from '../../../../assets/technologies-logo/angularjs.png';
import TL_Node from '../../../../assets/technologies-logo/nodejs.png';
import TL_Python from '../../../../assets/technologies-logo/python.png';
import TL_Android from '../../../../assets/technologies-logo/android.png';
import TL_Kotlin from '../../../../assets/technologies-logo/kotlin.png';
import TL_IOS from '../../../../assets/technologies-logo/iOS.png';
import TL_Swift from '../../../../assets/technologies-logo/swift.png';
import TL_JS from '../../../../assets/technologies-logo/javascript.png';
import TL_CSharp from '../../../../assets/technologies-logo/c#.png';
import TL_UiPath from '../../../../assets/technologies-logo/uipath.png';
import TL_BluePrism from '../../../../assets/technologies-logo/blueprism.png';
import TL_AutoAny from '../../../../assets/technologies-logo/automation-anywhere.png';
import TL_AWS from '../../../../assets/technologies-logo/aws.png';
import TL_Azure from '../../../../assets/technologies-logo/azure.png';
import TL_GCloud from '../../../../assets/technologies-logo/googlecloud.png';

import './ServicesCards.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/effect-coverflow';


export default function ServicesCards(){
    return(
        <Swiper effect={'coverflow'} navigation={true} loop={true} spaceBetween={50} slidesPerView={3} modules={[EffectCoverflow, Navigation]} 
            breakpoints={{0:{slidesPerView: 1, spaceBetween:20}, 900: {slidesPerView: 3, spaceBetween:50} }}
            coverflowEffect={{rotate: 0,stretch: 0,depth: 100,modifier: 1,slideShadows: false}}
        >
            {/*WEB DESIGN & DEVELOPMENT*/}
            <SwiperSlide>
                <Card className='card-container'>
                    <Box className='card-icon-container'>
                        <img src={CardIconWeb} className='card-icon' alt='Ícono diseño y desarrollo web' />
                    </Box>
                    <Typography variant='h5' className='card-title'>
                        Diseño y Desarrollo Web
                    </Typography>
                    <Typography variant='h6' className='card-description'>
                        Somos expertos en la creación de plataformas intuitivas y responsivas, transformando tus ideas
                        en sitios web impactantes y eficientes.
                    </Typography>
                    <Grid container>
                        <Grid item xs={3}><img src={TL_Angular} className="technology-icon" alt='AngularJS Logo' /></Grid>
                        <Grid item xs={3}><img src={TL_React} className="technology-icon" alt='ReactJS Logo' /></Grid>
                        <Grid item xs={3}><img src={TL_Node} className="technology-icon" alt='NodeJS Logo' /></Grid>
                        <Grid item xs={3}><img src={TL_Python} className="technology-icon" alt='Python Logo' /></Grid>
                    </Grid>
                </Card>
            </SwiperSlide>
            {/*APP DESIGN & DEVELOPMENT*/}
            <SwiperSlide>
                <Card className='card-container'>
                    <Box className='card-icon-container'>
                        <img src={CardIconApp} className='card-icon' alt='Ícono diseño y desarrollo de aplicaciones' />
                    </Box>
                    <Typography variant='h5' className='card-title'>
                        Aplicaciones Móviles
                    </Typography>
                    <Typography variant='h6' className='card-description'>
                        Desarrollamos aplicaciones móviles a medida, combinando innovación y funcionalidad garantizando
                        una experiencia de usuario excepcional.
                    </Typography>
                    <Grid container>
                        <Grid item xs={3}><img src={TL_Kotlin} className="technology-icon" alt='Kotlin Logo' /></Grid>
                        <Grid item xs={3}><img src={TL_Android} className="technology-icon" alt='Android Logo' /></Grid>
                        <Grid item xs={3}><img src={TL_IOS} className="technology-icon" alt='iOS Logo' /></Grid>
                        <Grid item xs={3}><img src={TL_Swift} className="technology-icon" alt='Swift Logo' /></Grid>
                    </Grid>
                </Card>
            </SwiperSlide>
            {/*INTERNET OF THINGS*/}
            <SwiperSlide>
                <Card className='card-container'>
                    <Box className='card-icon-container'>
                        <img src={CardIconIoT} className='card-icon' alt='Ícono IoT' />
                    </Box>
                    <Typography variant='h5' className='card-title'>
                        IoT | Internet de las Cosas
                    </Typography>
                    <Typography variant='h6' className='card-description'>
                        Lideramos la revolución IoT, integrando con eficiencia y seguridad dispositivos inteligentes 
                        que mejoran la vida cotidiana y los negocios.
                    </Typography>
                    <Grid container>
                        <Grid item xs={4}><img src={TL_Python} className="technology-icon" alt='Python Logo' /></Grid>
                        <Grid item xs={4}><img src={TL_JS} className="technology-icon" alt='Javascript Logo' /></Grid>
                        <Grid item xs={4}><img src={TL_CSharp} className="technology-icon" alt='C# Logo' /></Grid>
                    </Grid>
                </Card>
            </SwiperSlide>
            {/*AUTOMATION*/}
            <SwiperSlide>
                <Card className='card-container'>
                    <Box className='card-icon-container'>
                        <img src={CardIconAuto} className='card-icon' alt='Ícono Automatización de Procesos' />
                    </Box>
                    <Typography variant='h5' className='card-title'>
                        Automatización de Procesos
                    </Typography>
                    <Typography variant='h6' className='card-description'>
                        Optimizamos tus operaciones empresariales, reduciendo tiempos y maximizando resultados. 
                        Eleva tu productividad con nuestras estrategias y soluciones.
                    </Typography>
                    <Grid container>
                        <Grid item xs={3}><img src={TL_JS} className="technology-icon" alt='Javascript Logo' /></Grid>
                        <Grid item xs={3}><img src={TL_UiPath} className="technology-icon" alt='UiPath Logo' /></Grid>
                        <Grid item xs={3}><img src={TL_BluePrism} className="technology-icon" alt='Blue Prism Logo' /></Grid>
                        <Grid item xs={3}><img src={TL_AutoAny} className="technology-icon" alt='Automation Anywhere Logo' /></Grid>
                    </Grid>
                </Card>
            </SwiperSlide>
            {/*CLOUD ARCHITECTURES & SERVICES*/}
            <SwiperSlide>
                <Card className='card-container'>
                    <Box className='card-icon-container'>
                        <img src={CardIconCloud} className='card-icon' alt='Ícono Arquitectura y Servicios Cloud' />
                    </Box>
                    <Typography variant='h5' className='card-title'>
                        Arquitectura y Servicios Cloud
                    </Typography>
                    <Typography variant='h6' className='card-description'>
                        Ofrecemos soluciones en la nube, seguras, escalables y adaptadas a tus necesidades
                        específicas para impulsar la transformación digital de tu empresa.
                    </Typography>
                    <Grid container>
                        <Grid item xs={4}><img src={TL_AWS} className="technology-icon" alt='Amazon Web Services Logo' /></Grid>
                        <Grid item xs={4}><img src={TL_Azure} className="technology-icon" alt='Microsoft Azuure Logo' /></Grid>
                        <Grid item xs={4}><img src={TL_GCloud} className="technology-icon" alt='Google Clod Logo' /></Grid>
                    </Grid>
                </Card>
            </SwiperSlide>
            {/*CONSULTING SERVICES*/}
            <SwiperSlide>
                <Card className='card-container'>
                    <Box className='card-icon-container'>
                        <img src={CardIconConsult} className='card-icon' alt='Ícono Consultoría Tecnológica' />
                    </Box>
                    <Typography variant='h5' className='card-title'>
                        Consultoría Tecnológica
                    </Typography>
                    <Typography variant='h6' className='card-description'>
                        Nuestro equipo de expertos brinda asesoramiento tecnológico estratégico, ayudándote a navegar
                        con confianza en el cambiante paisaje digital.
                    </Typography>
                </Card>
            </SwiperSlide>
        </Swiper>
    );
}