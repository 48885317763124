/*
Author: Hoi Tak Leung
Email: hoi.leung@greedy.mx
Last Update: 26/09/2023
*/

import React from "react";

import { Typography, Container } from '@mui/material';

import './LegalDocument.css';

export default function Cookies(){
    return(
        <Container>
            <Typography variant="h3" className='document-header'>Política de Cookies</Typography>
            <Typography variant="h6" className='document-content'>
            Lorem ipsum dolor sit amet consectetur adipiscing elit fringilla curabitur integer, sagittis tempor class gravida massa netus donec nullam 
            nunc, at lectus vestibulum felis purus hendrerit arcu eget scelerisque. Orci mauris est imperdiet metus torquent libero, praesent 
            scelerisque ad lacinia blandit nam, mollis dictumst turpis tincidunt leo. Dictum ultrices morbi purus suscipit cum cras, urna curabitur 
            facilisis pellentesque dignissim, eget fringilla ante nostra conubia.
            Faucibus ligula torquent dis dictumst auctor elementum consequat sociosqu, dui aliquet nisl vestibulum accumsan vel magnis curae, augue 
            porta platea suspendisse mollis felis dapibus. Viverra pharetra diam morbi blandit id sollicitudin massa habitasse ac, tempor turpis congue 
            nascetur senectus integer potenti posuere.
            Iaculis inceptos sociis praesent montes facilisi ornare vivamus morbi feugiat mi felis, torquent est eros egestas primis neque sociosqu 
            condimentum velit cum, bibendum vitae et eu leo cubilia vehicula nulla senectus arcu. Ornare purus torquent dui ac porta quam aliquet 
            mattis tristique arcu, dis conubia risus suscipit curabitur penatibus magna cum cubilia duis nec, viverra rutrum parturient sociosqu id 
            nullam nisl litora fusce. Natoque consequat semper nec erat torquent maecenas et, tortor neque habitant dictum eget odio aliquam fringilla, 
            turpis nisi ultrices porttitor scelerisque orci. Habitasse felis ornare eleifend gravida eget inceptos tincidunt magna elementum mauris, 
            curabitur laoreet facilisis ad in himenaeos venenatis viverra fames cum, cursus nibh sem fermentum semper torquent fringilla congue tempus.
            Fames interdum tortor vivamus sociosqu facilisi diam, mus nam ultricies at conubia. .
            Sollicitudin porta scelerisque habitasse egestas nascetur commodo fames, facilisis pharetra dui hendrerit ac ligula congue, mollis mi 
            urna faucibus erat venenatis. Vulputate dictum cras eget neque suspendisse est nascetur ullamcorper pretium facilisis fringilla dictumst, 
            arcu metus commodo varius ut egestas elementum vestibulum sapien proin phasellus. Nibh elementum potenti litora mi fusce sodales 
            pellentesque tempus mattis tortor, ad dictumst nisl auctor praesent eleifend pharetra aptent hac. Duis quam habitant at curabitur 
            volutpat malesuada convallis habitasse, ac conubia suscipit lacus porta feugiat cursus ornare, sodales libero purus iaculis varius 
            neque blandit.
            Sem hac bibendum risus ante tellus nam volutpat habitasse, lacinia at tempus viverra quis conubia mattis, nibh rutrum porttitor vestibulum 
            senectus primis cum. Mollis venenatis risus mus quisque ad blandit himenaeos eros, nam conubia parturient accumsan aptent non porta tortor,
            integer magnis penatibus dapibus facilisis turpis tempus. Condimentum magna phasellus iaculis magnis accumsan ornare metus cubilia torquent 
            bibendum nam, nulla natoque euismod donec augue neque ultricies interdum faucibus.
            Hendrerit cras integer lectus litora nec vestibulum, tristique accumsan habitant etiam interdum tincidunt, luctus rhoncus lacinia cubilia 
            duis. Elementum montes consequat praesent curae velit laoreet malesuada cras, purus nascetur mus parturient libero nullam nunc, vestibulum 
            nostra vel sagittis feugiat netus eleifend. Conubia sollicitudin cubilia tortor sapien sociosqu integer nunc laoreet, nascetur metus 
            vestibulum magnis proin ridiculus tempor in tincidunt, venenatis aptent nostra ac arcu magna nisi.
            Convallis eros pharetra sagittis tempus non habitant donec varius sociis penatibus mus, quisque luctus cursus orci in sodales magna purus 
            torquent massa, volutpat eleifend aliquet feugiat posuere litora imperdiet sollicitudin mollis dignissim. Justo aenean euismod litora 
            blandit vel orci fames, dapibus risus tempus non tempor vitae tortor netus, cum inceptos interdum pellentesque conubia sodales. Per et vel 
            sociosqu proin nec scelerisque iaculis ultrices, phasellus quam nisi massa laoreet arcu primis lobortis viverra, rutrum tempor vulputate 
            semper lacus felis sed. Per arcu sapien porta ultrices placerat consequat dignissim sed litora dapibus, tristique sem in tempus sociis 
            imperdiet posuere vulputate eget varius, dictumst gravida condimentum ultricies cum malesuada neque accumsan penatibus. Consequat 
            accumsan ante ligula sollicitudin fusce nunc, arcu erat eget dignissim metus magna tortor, conubia facilisi cubilia tempor primis.
            Iaculis sagittis venenatis orci molestie donec libero cubilia conubia netus, egestas torquent purus maecenas ac convallis natoque 
            fringilla nascetur ornare, nostra sed metus curabitur cursus blandit est duis. Quam tellus vestibulum faucibus integer ac lacus platea 
            parturient natoque pulvinar euismod, orci augue per venenatis porta aliquet nec et duis montes. Orci duis nunc quisque gravida vitae 
            eleifend platea aliquet, tempor vulputate posuere litora velit praesent sed, fringilla iaculis non viverra mollis dignissim tellus.
            Montes vehicula orci blandit ac cursus justo ad nec sociosqu, vitae est proin magna sed consequat turpis augue urna, mi rhoncus 
            suspendisse suscipit parturient enim phasellus placerat. Libero quam cubilia aliquet conubia est semper imperdiet aenean eleifend, dis 
            erat euismod pulvinar bibendum et suspendisse nullam mollis, felis massa cursus dapibus nisi fermentum litora a. Porttitor pharetra aenean 
            natoque platea per nisl lacus elementum congue mattis neque, nascetur penatibus turpis est senectus eros mi ad himenaeos diam nibh 
            commodo, potenti erat aliquet montes tincidunt massa egestas nullam quam nunc. Cursus posuere habitasse placerat gravida id ad, purus 
            ante eget venenatis nulla scelerisque, lobortis phasellus justo ultrices sociis.
            </Typography>
        </Container>         
    );
}