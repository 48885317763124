/*
Author: Hoi Tak Leung
Email: hoi.leung@greedy.mx
Last Update: 21/09/2023
*/

import React from "react";
import { Typography, Container, CssBaseline, Grid, Box, FormControl, 
        InputLabel, Input, InputAdornment, TextField, Button } from '@mui/material';

import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import SendIcon from '@mui/icons-material/Send';

import './ContactSection.css';

export default function ContactSection(){
    return(
        <Container disableGutters maxWidth={false} className='section-contact-container'>
            <CssBaseline />
            <Grid container className='section-content'>
                <Grid item xs={1} lg={1} xl={2}></Grid>
                <Grid item  xs={10} lg={10} xl={8}>
                    <Box className='contact-form-container'>
                        <Typography variant="h3" className="contact-form-header">¡Seamos Partners!</Typography>
                        <Typography variant="h6" className="contact-form-description">
                            Estamos muy emocionados en hacer equipo contigo y convertir en realidad tu visión de negocio.
                            Nos puedes contactar a través del siguiente formulario o con los siguientes datos:
                        </Typography>
                        <Grid container className="contact-form-grid">
                            <Grid item container lg={7} xl={7} sx={{borderRight:{lg:'1px solid gray'}}}>
                                <Grid item xs={12} lg={6} xl={6} className="form-field">
                                    <FormControl fullWidth variant="standard">
                                        <InputLabel>Nombre</InputLabel>
                                        <Input startAdornment={
                                            <InputAdornment position="start">
                                                <AccountCircleOutlinedIcon />
                                            </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={6} xl={6} className="form-field">
                                    <FormControl fullWidth variant="standard">
                                        <InputLabel>Correo Electrónico</InputLabel>
                                        <Input startAdornment={
                                            <InputAdornment position="start">
                                                <EmailOutlinedIcon />
                                            </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={6} xl={6} className="form-field">
                                    <FormControl fullWidth variant="standard">
                                        <InputLabel>Compañía</InputLabel>
                                        <Input startAdornment={
                                            <InputAdornment position="start">
                                                <WorkOutlineOutlinedIcon />
                                            </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={6} xl={6} className="form-field">
                                    <FormControl fullWidth variant="standard">
                                        <InputLabel>Teléfono</InputLabel>
                                        <Input startAdornment={
                                            <InputAdornment position="start">
                                                <LocalPhoneOutlinedIcon />
                                            </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} className="form-field">
                                    <TextField
                                    fullWidth
                                    id="standard-multiline-static"
                                    label="Cuéntanos tu idea/proyecto"
                                    multiline
                                    rows={4}
                                    variant="standard"
                                    />
                                </Grid>
                                <Grid item xs={12} className="form-field">
                                    <Button className="btn-send-form" variant='contained' startIcon={<SendIcon />}>
                                        ENVIAR
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid item lg={5} xl={5} className="contact-box-container">
                                <Box className='contact-box'>
                                    <Box sx={{paddingBottom:'2vh'}}>
                                        <Typography variant="h6">Correo Electrónico:</Typography>
                                        <Typography variant="body">contacto@greedy.mx</Typography>
                                    </Box>
                                    <Box sx={{paddingBottom:'2vh'}}>
                                        <Typography variant="h6">Teléfono de Contacto:</Typography>
                                        <Typography variant="body">+52 55 2759 3304</Typography>
                                    </Box>
                                    <Box>
                                        <Typography variant="h6">Dirección:</Typography>
                                        <Typography variant="body">
                                            Fray Servando Teresa de Mier 628 Int. 4, Aeronáutica Militar, 
                                            Venustiano Carranza, 15970, CDMX
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            
                        </Grid>
                    </Box>
                </Grid>
                <Grid item xs={1} lg={1} xl={2}></Grid>
            </Grid>
        </Container>
    );
}