/*
Author: Hoi Tak Leung
Email: hoi.leung@greedy.mx
Last Update: 25/09/2023
*/

import React from "react";
import { Typography, Container, CssBaseline, Grid } from '@mui/material';

import ServicesCards from './services-cards/ServicesCards';

import './ServicesSection.css';

export default function ServicesSection(){
    return(
        <Container disableGutters maxWidth={false} className='container-section'>
            <CssBaseline />
            <Grid container className='section-content'>
                <Grid item xs={1} lg={1} xl={2}></Grid>
                <Grid item xs={10} lg={10} xl={8}>
                    <Typography variant="h3" className='section-header'>Nuestros Servicios</Typography>
                    <Typography variant="h5" className='section-description'>
                        Contamos con una amplia gama de servicios diseñados para satisfacer las necesidades tecnológicas de tu empresa,
                        centrándonos en soluciones innovadoras, eficientes y a la medida que nos conviertan en tu socio de tecnología ideal. 
                    </Typography>
                    <ServicesCards />
                </Grid>
                <Grid item xs={1} lg={1} xl={2}></Grid>
            </Grid>
        </Container>
    );
}