/*
Author: Hoi Tak Leung
Email: hoi.leung@greedy.mx
Last Update: 21/09/2023
*/

import React from "react";
import { Typography, Container, CssBaseline, Grid, Link, IconButton, Box } from '@mui/material';

import greedyLogo from '../../assets/greedy_logo_circular_solid_64x64.png';
import greedyImagotype from '../../assets/greedy_logo_blue_350x75.png';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';

import './Footer.css';

export default function Footer(){
    const openTab = (url) =>{
        window.open(url,'_blank');
    }
    // ----------------------------- RENDER -----------------------------
    return(
        <Container disableGutters maxWidth={false} className='container-footer'>
            <CssBaseline />
            <Grid container className='footer-content'>
                <Grid item xl={2} lg={2}></Grid>
                <Grid item xl={8} lg={8}>
                    <Grid container>
                        <Grid item xs={12} lg={10} xl={10}  className='footer-grid-contact'>
                            <Box sx={{display:{xs:'none', sm:'none', lg:'flex', xl:'flex'}}}>
                                <img src={greedyLogo} alt='Logo Greedy'/>
                            </Box>
                            <Box sx={{display:{xs:'block', sm:'block', lg:'none', xl:'none'}}}>
                                <img src={greedyImagotype} className="logo-imagotype" alt='Logo Greedy'/>
                            </Box>
                        </Grid>
                        <Grid item container xs={12} lg={2} xl={2}  className='grid-social-media'>
                            <Grid item xs={3} sx={{margin:'auto'}}>
                                <IconButton size='small' onClick={()=> openTab('https://www.linkedin.com/company/greedymexico')}>
                                    <LinkedInIcon className='social-media-icon'/>
                                </IconButton>
                            </Grid>
                            <Grid item xs={3} sx={{margin:'auto'}}>
                                <IconButton size='small' onClick={()=> openTab('https://www.facebook.com/greedy.mexico')}>
                                    <FacebookOutlinedIcon className='social-media-icon'/>
                                </IconButton>
                            </Grid>
                            <Grid item xs={3} sx={{margin:'auto'}}>
                                <IconButton size='small' onClick={()=> openTab('https://www.instagram.com/greedy.mexico/')}>
                                    <InstagramIcon className='social-media-icon'/>
                                </IconButton>
                            </Grid>
                            <Grid item xs={3} sx={{margin:'auto'}}>
                                <IconButton size='small' onClick={()=> openTab('https://twitter.com/GreedyMexico')}>
                                    <TwitterIcon className='social-media-icon'/>
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Grid item container xl={12} lg={12} className='grid-legal'>
                            <Grid item xs={12} sm={12} md={4}  sx={{textAlign:{xs:'center', lg:'left', xl:'left'}}}>
                                <Typography variant='body'>COPYRIGHT &copy; 2023 GREEDY</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={8}  className='grid-legal-links'
                                sx={{display:{xs:'flex', sm:'inline-flex', lg:'flex', xl:'flex'}}}>
                                <Box className="grid-legal-box">
                                    <Link className='legal-link' href="/legal/terms">Términos y Condiciones</Link>
                                </Box>
                                <Box className="grid-legal-box">
                                    <Link className='legal-link' href="/legal/privacy">Aviso de Privacidad</Link>
                                </Box>
                                <Box className="grid-legal-box">
                                    <Link className='legal-link' href="/legal/cookies">Política de Cookies</Link>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xl={2} lg={2}></Grid>
            </Grid>
        </Container>
    );
}