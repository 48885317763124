/*
Author: Hoi Tak Leung
Email: hoi.leung@greedy.mx
Last Update: 26/09/2023
*/

import React, { useState } from "react";

import { AppBar, Box, Toolbar, Typography, Container, CssBaseline, IconButton, Drawer, List, ListItem, ListItemButton, ListItemText } from '@mui/material';

import ServicesSection from "./section-services/ServicesSection";
import IndustriesSection from "./section-industries/IndustriesSection";
import AboutSection from "./section-about-us/AboutSection";
import ContactSection from "./section-contact/ContactSection";
import Footer from "../footer/Footer";

import './Landing.css';
import backgroundVideo from '../../assets/1100606505-preview.mp4';
import backgroundImgMobile from '../../assets/landing-page/landing_sm_back.jpg';
import blueLogo from '../../assets/greedy_logo_blue_350x75.png';
import blueTextLogo from '../../assets/greedy_text_blue_188x54.png';
import whiteLogo from '../../assets/greedy_logo_white_350x75.png';
import whiteTextLogo from '../../assets/greedy_text_white_188x54.png';

import MenuIcon from "@mui/icons-material/Menu";

const sections = ["SERVICIOS", "INDUSTRIAS", "NOSOTROS", "CONTACTO"];

export default function Landing(){
    const handleLogoClick = () =>{
        window.location.href = '/';
    }
    const handleMenuSelection = (target) =>{
        var target_section = '';
        switch(target){
            case 'SERVICIOS':
                target_section = 'section-services';
                break;
            case 'INDUSTRIAS':
                target_section = 'section-industries';
                break;
            case 'NOSOTROS':
                target_section = 'section-about-us';
                break;
            case 'CONTACTO':
                target_section = 'section-contact';
                break;
            default:
                target_section = 'section-home';
        }
        var element = document.getElementById(target_section);
        var elementPos = element.getBoundingClientRect().top;
        window.scrollTo({
            top: elementPos + window.scrollY - 64,
            behavior: 'smooth'
        });
        setDrawerState(false);
    }
    const [drawerState, setDrawerState] = useState(false);
    const toggleDrawer = () => (event) =>{
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerState(!drawerState);
    }
    const [navClass, setNavClass] = useState(false);
    const changeNavClass = () =>{
        if (window.scrollY >= 10){
            setNavClass(true);
        }
        else{
            setNavClass(false);
        }
    }
    window.addEventListener('scroll', changeNavClass);
    return(
        <Container disableGutters maxWidth={false} className="landing-container">
            <CssBaseline />
            <AppBar className={ navClass ? 'navbar-solid' : 'navbar-transparent'}>
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                    <Box className='navbar-logo' onClick={()=>{handleLogoClick()}} sx={{ display: { xs: "none", md: "flex" }}}>
                        <img src={navClass ? blueLogo : whiteLogo} alt="Greedy Logo"/>
                    </Box>
                    <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                        <IconButton size="large" onClick={toggleDrawer()} color="inherit">
                            <MenuIcon />
                        </IconButton>
                    </Box>
                    <Box className="mobile-navbar-logo" onClick={()=>{handleLogoClick()}} sx={{ display: { xs: "flex", md: "none" }}}>
                        <img src={navClass ? blueTextLogo : whiteTextLogo}  alt="Greedy Logo"/>
                    </Box>
                    <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
                        {sections.map((section)=>(
                            <Box key={section} onClick={()=>{handleMenuSelection(section)}} className="navbar-page">
                                <Typography variant="h6">{section}</Typography>
                            </Box>
                        ))}
                    </Box>
                    </Toolbar>
                </Container>
            </AppBar>
            <Drawer anchor={'top'} open={drawerState} onClose={toggleDrawer()}>
                <Box role="presentation">
                    <List>
                        {sections.map((section)=>(
                            <ListItem key={section} disablePadding>
                                <ListItemButton onClick={()=>{handleMenuSelection(section)}}>
                                    <ListItemText primary={section}></ListItemText>
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </Drawer>
            <Container maxWidth={false} disableGutters sx={{display: {xs: 'none', sm:'none', lg:'block', xl: 'block'}}} className="container-video">
                <video autoPlay loop muted id='background-video'>
                    <source src={backgroundVideo} type="video/mp4" />
                </video>
                <Typography variant="h2" className="video-overlay">El partner de tecnología <br /> que buscabas</Typography>
            </Container>
            <Container sx={{display: {xs: 'block', sm: 'block', lg:'none', xl: 'none'}}} maxWidth={false} disableGutters>
                <img src={backgroundImgMobile} className="background-image" alt='Imagen de Fondo Tecnología'/>
            </Container>
            <div id="section-services"><ServicesSection /></div>
            <div id="section-industries"><IndustriesSection /></div>
            <div id="section-about-us"><AboutSection /></div>
            <div id="section-contact"><ContactSection /></div>
            <Footer />
        </Container>
            
    );
}